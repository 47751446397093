<template>
  <div style="height: 60vh;">
    <div
      class="card card-custom gutter-b"
      style="height: 60vh; overflow-y: auto"
    >
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Report By Locations:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
        <div class="card-toolbar">
          <button
            @click.prevent="exportAsExcel"
            class="btn btn-light-primary font-weight-bold px-10"
          >
            Export .xlsx
          </button>
        </div>
      </div>
      <div class="card-body pt-0">
        <DeviceReportTable
          v-if="isGenerated"
          :traffic="traffic"
          :selectedDevices="selected"
          :isTokenColumnVisible="true"
          @data-sent="handleGeneratedResults"
        ></DeviceReportTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeviceReportTable from '../../../content/components/device-report-table/DeviceReportTable'
import * as XLSX from 'xlsx';

export default {
  name: 'LessorTraffic',
  props: {
    traffic: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  components: {
    DeviceReportTable,
  },
  data() {
    return {
      isGenerated: false,
      exportData: []
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    setTimeout(() => {
      this.isGenerated = true
    }, 500)
  },
  methods: {
    exportAsExcel() {
      const ws = XLSX.utils.json_to_sheet(this.exportData);
      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Export the workbook as an Excel file
      XLSX.writeFile(wb, 'finacial-analysis-by-device.xlsx');
    },
    handleGeneratedResults(data) {
      this.exportData = data;
    }
  },
}
</script>
